export const APP_NAME = 'LuckyDraw';

export const NAVBAR_ITEMS = [
  {
    value: 'Home',
    to: '/',
    icon: 'home',
    useExact: true,
  },
  {
    value: 'Lucky Draw',
    icon: 'box',
    to: '/',
  },
  // {
  //   value: 'FAQ',
  //   icon: 'list',
  //   to: '/faq',
  // },
  {
    value: 'LIST',
    icon: 'list',
    to: '/list',
  },
];

export const SPONSORS = [
  {
    name: '3P Academy',
    url: 'https://3pacademy.vn',
    img: './images/3pacademy-logo.png',
  },
  // {
  //   name: 'Kitticon',
  //   url: 'https://www.kitticon.com/',
  //   img: './images/kitticon-logo.png',
  // },
];

export const walletDonate = {
  img: './images/donate-image-usdt.jpg',
  wallet: '0x5Aa76a7805DCb10939c3Cb65865F7E0B6625Ed19',
  name: 'data',
}
